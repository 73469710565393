import React, { useEffect, useMemo, useRef } from "react";
import ContactForm from "./ContactForm";
import { iframeCss } from "./App";

function postResizeMessage() {
  if (window.parent) {
    [
      "https://my.hd:3000",
      "https://app.labs.helpdesk.com",
      "https://app.helpdesk.com",
    ].forEach((trustedDomain) => {
      window.parent.postMessage(
        { iframeHeight: window.document.body.scrollHeight },
        trustedDomain
      );
    });
  }
}

function fromBinary(binary: string): string {
  const bytes = new Uint8Array(binary.length);
  for (let i = 0; i < bytes.length; i++) {
    bytes[i] = binary.charCodeAt(i);
  }
  const charCodes = new Uint16Array(bytes.buffer);
  let result = "";
  for (let i = 0; i < charCodes.length; i++) {
    result += String.fromCharCode(charCodes[i]);
  }
  return result;
}

function decodePreviewData(data: string): Record<string, any> {
  return JSON.parse(fromBinary(atob(data)));
}

const FormPreview: React.FC<{ data: string }> = ({ data }) => {
  const formData = useMemo(() => decodePreviewData(data), [data]);

  const {
    header,
    caption,
    confirmation,
    nameLabel,
    emailLabel,
    subjectLabel,
    messageLabel,
    namePlaceholder,
    emailPlaceholder,
    subjectPlaceholder,
    messagePlaceholder,
    sendLabel,
    sendErrorLabel,
    sendSuccessLabel,
    sendLoadingLabel,
    sendErrorMessage,
    isEnterprise: hideFooter,
    nameRequiredError,
    emailRequiredError,
    emailInvalidError,
    subjectRequiredError,
    messageRequiredError,
    consentRequiredError,
  } = formData;

  const consentRequired = formData.consentMode === "checkbox";
  const consentMessage = ["checkbox", "info"].includes(formData.consentMode)
    ? formData.consentMessage
    : null;

  const iframeRef = useRef<HTMLDivElement>(null);

  const formProps = {
    header,
    caption,
    confirmation,
    nameLabel,
    emailLabel,
    subjectLabel,
    messageLabel,
    namePlaceholder,
    emailPlaceholder,
    subjectPlaceholder,
    messagePlaceholder,
    sendLabel,
    sendErrorLabel,
    sendLoadingLabel,
    sendSuccessLabel,
    sendErrorMessage,
    isPreview: true,
    consentRequired,
    consentMessage,
    hideFooter,
    nameRequiredError,
    emailRequiredError,
    emailInvalidError,
    subjectRequiredError,
    messageRequiredError,
    consentRequiredError,
  };

  useEffect(() => {
    postResizeMessage();
    window.document.body.addEventListener("resize", postResizeMessage);
    window.addEventListener("load", postResizeMessage);

    return () => {
      window.document.body.removeEventListener("resize", postResizeMessage);
      window.removeEventListener("load", postResizeMessage);
    };
  }, []);

  return (
    <div css={iframeCss} ref={iframeRef}>
      <ContactForm {...formProps} />
    </div>
  );
};

export default FormPreview;
