import { Loader } from "@livechat/design-system";
import React, { useEffect, useState } from "react";
import { FullScreenLoader, Error, iframeCss } from "./App";
import ContactForm, { ContactFormProps } from "./ContactForm";

type EmbedProps = {
  type: "embed";
  licenseID: number;
  rootUrl: string;
  formID: string;
};

type BoosterProps = {
  type: "booster";
  livechatLicenseID: string;
  rootUrl: string;
  groupID: string;
};

type ModernContactFormProps = EmbedProps | BoosterProps;

function isBoosterProps(props: ModernContactFormProps): props is BoosterProps {
  return props.type === "booster";
}

function isEmbedProps(props: ModernContactFormProps): props is EmbedProps {
  return props.type === "embed";
}

const ModernContactForm: React.FC<ModernContactFormProps> = (props) => {
  const [viewState, setViewState] = useState<"loading" | "error" | "success">(
    "loading"
  );
  const [data, setData] = useState<ContactFormProps | null>(null);
  const isBooster = isBoosterProps(props);

  useEffect(() => {
    (async () => {
      try {
        const { rootUrl } = props;
        let response;

        if (isBoosterProps(props)) {
          const { groupID, livechatLicenseID } = props;
          response = await window.fetch(
            `${rootUrl}/v1/contactForms/formSettings?lcLicenseID=${livechatLicenseID}&lcGroupID=${groupID}`,
            {
              method: "get",
              headers: { "Content-Type": "application/json" },
            }
          );
        } else if (isEmbedProps(props)) {
          const { rootUrl, licenseID, formID } = props;
          response = await window.fetch(
            `${rootUrl}/v1/contactForms/formSettings?licenseID=${licenseID}&contactFormID=${formID}`,
            {
              method: "get",
              headers: { "Content-Type": "application/json" },
            }
          );
        } else {
          setViewState("error");
          return;
        }

        if (response.status > 399) {
          setViewState("error");
        } else {
          const formData = await response.json();
          const {
            ID,
            header,
            caption,
            licenseID,
            confirmation,
            nameLabel,
            emailLabel,
            subjectLabel,
            messageLabel,
            namePlaceholder,
            emailPlaceholder,
            subjectPlaceholder,
            messagePlaceholder,
            sendLabel,
            sendErrorLabel,
            sendSuccessLabel,
            sendLoadingLabel,
            sendErrorMessage,
            poweredByFooter,
            teamID,
            nameRequiredError,
            emailRequiredError,
            emailInvalidError,
            subjectRequiredError,
            messageRequiredError,
            consentRequiredError,
          } = formData;

          const consentRequired = formData.consentMode === "checkbox";
          const consentMessage = ["checkbox", "info"].includes(
            formData.consentMode
          )
            ? formData.consentMessage
            : null;
          const hideFooter = !poweredByFooter;

          const formProps = {
            contactFormID: ID,
            header,
            caption,
            confirmation,
            nameLabel,
            emailLabel,
            subjectLabel,
            messageLabel,
            namePlaceholder,
            emailPlaceholder,
            subjectPlaceholder,
            messagePlaceholder,
            sendLabel,
            sendErrorLabel,
            sendLoadingLabel,
            sendSuccessLabel,
            sendErrorMessage,
            consentRequired,
            consentMessage,
            hideFooter,
            teamID,
            rootUrl,
            licenseID,
            nameRequiredError,
            emailRequiredError,
            emailInvalidError,
            subjectRequiredError,
            messageRequiredError,
            consentRequiredError,
          };

          setViewState("success");
          setData(formProps);
        }
      } catch (e) {
        console.error(e);
        setViewState("error");
      }
    })();
  }, [props]);

  switch (viewState) {
    case "loading":
      return (
        <FullScreenLoader>
          <Loader size="large" />
        </FullScreenLoader>
      );
    case "success":
      return (
        <div css={iframeCss}>
          <ContactForm {...data!} isBooster={isBooster} />
        </div>
      );
    case "error":
    default:
      return (
        <FullScreenLoader>
          <Error />
        </FullScreenLoader>
      );
  }
};

export default ModernContactForm;
