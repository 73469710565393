import {
  Button,
  CheckboxField,
  InputField,
  TextAreaField,
} from "@livechat/design-system";
import React, { useCallback, useRef, useState } from "react";
import { css } from "@emotion/core";
import {
  Formik,
  FieldProps,
  Form as FormikForm,
  Field,
  FormikErrors,
} from "formik";
import footerSvg from "./helpdesk-footer.svg";

type FormFields = {
  name: string;
  email: string;
  subject: string;
  message: string;
  consent: boolean;
};

type FormProps = {
  onSubmit(fields: FormFields): Promise<any>;
  isBooster?: boolean;
  header: string;
  confirmation: string;
  caption: string;
  hideFooter: boolean;
  consentRequired: boolean;
  consentMessage: string | null;
  nameLabel: string;
  emailLabel: string;
  subjectLabel: string;
  messageLabel: string;
  sendLoadingLabel: string;
  sendErrorMessage: string;
  namePlaceholder: string;
  emailPlaceholder: string;
  subjectPlaceholder: string;
  messagePlaceholder: string;
  sendLabel: string;
  sendSuccessLabel: string;
  sendErrorLabel: string;
  nameRequiredError: string;
  emailRequiredError: string;
  emailInvalidError: string;
  subjectRequiredError: string;
  messageRequiredError: string;
  consentRequiredError: string;
};

const formContainerCss = css`
  border-radius: 8px;
  border: 1px #dee2e6 solid;
  background-color: white;
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;
`;

const formContainerBorderlessCss = css`
  background-color: white;
  font-family: system-ui,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 14px;
`;

const formContentsCss = (isBooster: boolean) => css`
  padding: ${isBooster ? '20px' : '28px'};
`;

const rowCss = css`
  display: grid;
  padding: 12px 0;
  padding-bottom: 4px;
  grid-template-columns: 1fr;

  a {
    color: #4384f5;
    text-decoration: none;

    &:hover,
    &:active {
      text-decoration: underline;
    }
  }

  .consent-message {
    font-size: 15px;
    line-height: 19px;
    font-family: Source Sans Pro, sans-serif;
    margin-bottom: 1em;
  }

  .lc-checkbox__text {
    flex: 1 1 auto;
  }
`;

const rowNoMarginCss = css`
  padding: 0;
`;

const fullRowCss = css`
  display: grid;
  grid-template-columns: 1fr;
  padding: 12px 0;
`;

const fullWidthInput = (isBooster: boolean) => css`
  .lc-input,
  .lc-textarea {
    width: 100%;
    margin-bottom: ${isBooster ? '0' : '8px'};
  }

  .lc-field-error {
    margin: 0;
    position: relative;
    top: ${isBooster ? '0' : '-6px'};
    height:  ${isBooster ? 'auto' : '0'};;
    overflow: visible;
  }

  margin: 0;
`;

const formTextarea = css`
  .lc-textarea {
    min-height: 100px;
  }

  margin-bottom: 8px;
`;

const sendButton = css`
  max-width: 200px;
  transition: background-color 0.5s ease-in;
`;

const successButton = css`
  background-color: #4bb678;

  &:hover,
  &:active {
    background-color: #4bb678;
  }

  &:active {
    box-shadow: 0 0 0 1px #4bb678;
  }
`;

const errorButton = css`
  background-color: #d64646;

  &:hover,
  &:active {
    background-color: #d64646;
  }

  &:active {
    box-shadow: 0 0 0 1px #d64646;
  }
`;

const boosterRowCss = css`
  padding: 4px 0;
`;

const formHeader = (isBooster: boolean) => css`
  h1 {
    font-weight: ${isBooster ? '700' : '600'};;
    font-size: ${isBooster ? '16px' : '18px'};
    line-height: 22.6px;
    margin-top: 0;
    margin-bottom: 4px;
  }

  margin-bottom: ${isBooster ? '8px' : '28px'};
`;

const buttonRow = (isBooster: boolean) => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  align-items: start;

  button {
    margin-bottom: ${isBooster ? 0 : '24px'};
    flex-grow: 1;
  }
`;

const buttonCenteredRow = css`
  justify-content: space-around;
`;

const FormContainer: React.FC<{ isBooster?: boolean }> = ({
  children,
  isBooster = false,
}) => (
  <div css={isBooster ? formContainerBorderlessCss : formContainerCss} className={isBooster ? 'booster' : ''}>
    {children}
  </div>
);

const FormContents = ({ isBooster, children }: { isBooster: boolean, children: React.ReactNode }) => (
  <div css={formContentsCss(isBooster)}>{children}</div>
);

const Row: React.FC<{ full?: boolean; noMargin?: boolean, isBooster: boolean }> = ({
  full = false,
  noMargin = false,
  children,
  isBooster,
}) => (
  <div
    css={[full ? fullRowCss : rowCss, noMargin && rowNoMarginCss, isBooster && boosterRowCss].filter(
      Boolean
    )}
  >
    {children}
  </div>
);

const boosterSendButtonStyle = css`
  width: 100%;
  max-width: none;
  border-radius: 6px;
`

const helpdeskFooterCss = css`
  height: 32px;
  background-color: #f6f6f7;
  padding: 0 28px;
  display: flex;
  align-items: center;
  line-height: 14px;
  justify-content: flex-start;
  font-size: 12px;
  span {
    padding-right: 2px;
  }

  a {
    text-decoration: none;
    color: #000000;
  }

  img {
    margin-bottom: 2px;
    padding: 0;
    vertical-align: middle;
  }
`;

const generateConsentMessage = (consentMessage: string) => {
  const linkRegex = /\[([^[\]]+)\]\(([^()]+)\)/gm;
  const result = [];

  let match;
  let beginIndex = 0;

  while ((match = linkRegex.exec(consentMessage)) !== null) {
    const matchIndex = match.index;
    const label = match[1];
    const url = match[2];

    result.push(consentMessage.slice(beginIndex, matchIndex));
    result.push(
      <a
        key={match.index}
        href={url}
        rel="nofollow noreferrer noopener"
        target="_blank"
      >
        {label}
      </a>
    );
    beginIndex = linkRegex.lastIndex + 1;
  }

  result.push(consentMessage.slice(beginIndex));
  return result;
};

const HelpDeskFooter: React.FC = () => (
  <footer css={helpdeskFooterCss}>
    <a
      href="https://www.helpdesk.com/powered-by-helpdesk/?utm_source=contact-form&amp;utm_medium=referral"
      target="_blank"
      rel="noopener noreferrer"
    >
      <span>Powered by</span>{" "}
      <img src={footerSvg} alt="HelpDesk" width="74" height="14" />
    </a>
  </footer>
);

export const emailRegex = /^[A-Z0-9._%'+-]+@(?:[A-Z0-9-]+\.)+[A-Z]{2,14}$/i;
export function isEmail(value: string): boolean {
  return emailRegex.test(value);
}

enum ValidationError {
  NameRequired = "NAME_REQUIRED",
  EmailRequired = "EMAIL_REQUIRED",
  EmailInvalid = "EMAIL_INVALID",
  SubjectRequired = "SUBJECT_REQUIRED",
  MessageRequired = "MESSAGE_REQUIRED",
  ConsentRequired = "CONSENT_REQUIRED",
}

const validateForm = ({
  name,
  email,
  subject,
  message,
  consent,
}: FormFields) => {
  let errors: FormikErrors<FormFields> = {};

  if (name.trim().length === 0) {
    errors.name = ValidationError.NameRequired;
  }

  if (email.trim().length === 0) {
    errors.email = ValidationError.EmailRequired;
  }

  if (!isEmail(email)) {
    errors.email = ValidationError.EmailRequired;
  }

  if (subject.trim().length === 0) {
    errors.subject = ValidationError.SubjectRequired;
  }

  if (message.trim().length === 0) {
    errors.message = ValidationError.MessageRequired;
  }

  if (consent === false) {
    errors.consent = ValidationError.ConsentRequired;
  }

  return errors;
};

const initialValues: FormFields = {
  name: "",
  email: "",
  subject: "",
  message: "",
  consent: false,
};

const Form: React.FC<FormProps> = ({
  onSubmit,
  caption,
  header,
  confirmation,
  hideFooter,
  consentMessage,
  consentRequired,
  isBooster = false,
  ...formProps
}) => {
  const {
    nameLabel,
    emailLabel,
    subjectLabel,
    messageLabel,
    sendLoadingLabel,
    sendErrorMessage,
    namePlaceholder,
    emailPlaceholder,
    subjectPlaceholder,
    messagePlaceholder,
    sendLabel,
    sendSuccessLabel,
    sendErrorLabel,
    nameRequiredError,
    emailRequiredError,
    emailInvalidError,
    subjectRequiredError,
    messageRequiredError,
    consentRequiredError,
  } = formProps;

  const [isSuccess, setSuccess] = useState(false);
  const [isError, setError] = useState(false);
  let buttonLabel = sendLabel;
  if (isSuccess) {
    buttonLabel = sendSuccessLabel;
  } else if (isError) {
    buttonLabel = sendErrorLabel;
  }

  const errorMap: Record<ValidationError, string> = {
    [ValidationError.NameRequired]: nameRequiredError,
    [ValidationError.EmailRequired]: emailRequiredError,
    [ValidationError.EmailInvalid]: emailInvalidError,
    [ValidationError.MessageRequired]: messageRequiredError,
    [ValidationError.SubjectRequired]: subjectRequiredError,
    [ValidationError.ConsentRequired]: consentRequiredError,
  };

  const conditionalValidate = useCallback(
    (fields) => {
      const errors = validateForm(fields);

      if (errors.consent && !consentRequired) {
        delete errors.consent;
      }

      return (Object.keys(errors) as any).reduce(
        (errorObject: Record<string, string>, key: keyof FormFields) => {
          errorObject[key] = errorMap[errors[key] as ValidationError];
          return errorObject;
        },
        {} as Record<string, string>
      );
    },
    [consentRequired, errorMap]
  );

  return (
    <FormContainer isBooster={isBooster}>
      <FormContents isBooster={isBooster}>
        <Formik
          initialValues={initialValues}
          onSubmit={async (values: FormFields, { resetForm }) => {
            setError(false);
            setSuccess(false);
            try {
              const response = await onSubmit(values);
              if (response.status < 400) {
                setSuccess(true);
                resetForm({ values: initialValues });
              } else {
                setError(true);
              }
            } catch {
              setError(true);
            }
          }}
          isInitialValid={false}
          validate={conditionalValidate}
        >
          {({ isValid, isSubmitting }) => (
            <FormikForm>
              {caption && header && (
                <header css={formHeader(isBooster)}>
                  {header && <h1>{header}</h1>}
                  {caption && <p>{caption}</p>}
                </header>
              )}
              <Row isBooster={isBooster}>
                <Field name="name">
                  {({ field, meta }: FieldProps) => (
                    <InputField
                      css={fullWidthInput(isBooster)}
                      labelText={nameLabel}
                      id="name"
                      placeholder={namePlaceholder}
                      error={meta.touched ? meta.error : ""}
                      {...field}
                    />
                  )}
                </Field>
              </Row>
              <Row isBooster={isBooster}>
                <Field name="email">
                  {({ field, meta }: FieldProps) => (
                    <InputField
                      css={fullWidthInput(isBooster)}
                      labelText={emailLabel}
                      id="email"
                      placeholder={emailPlaceholder}
                      error={meta.touched ? meta.error : ""}
                      {...field}
                    />
                  )}
                </Field>
              </Row>
              <Row isBooster={isBooster}>
                <Field name="subject">
                  {({ field, meta }: FieldProps) => (
                    <InputField
                      css={fullWidthInput(isBooster)}
                      labelText={subjectLabel}
                      id="subject"
                      placeholder={subjectPlaceholder}
                      error={meta.touched ? meta.error : ""}
                      {...field}
                    />
                  )}
                </Field>
              </Row>
              <Row full isBooster={isBooster}>
                <Field name="message">
                  {({ field, meta }: FieldProps) => (
                    <TextAreaField
                      css={[fullWidthInput(isBooster), formTextarea]}
                      labelText={messageLabel}
                      placeholder={messagePlaceholder}
                      id="message"
                      error={meta.touched ? meta.error : ""}
                      {...field}
                    />
                  )}
                </Field>
              </Row>
              {consentMessage && (
                <Row isBooster={isBooster}>
                  <div className="consent-message">
                    {consentRequired ? (
                      <Field name="consent">
                        {({ field, meta }: FieldProps) => (
                          <CheckboxField
                            id="consent"
                            checked={field.value || false}
                            {...field}
                          >
                            {generateConsentMessage(consentMessage)}
                          </CheckboxField>
                        )}
                      </Field>
                    ) : (
                      <p>{generateConsentMessage(consentMessage)}</p>
                    )}
                  </div>
                </Row>
              )}
              <Row full isBooster={isBooster}>
                <div css={[buttonRow(isBooster), isBooster && buttonCenteredRow]}>
                  <Button
                    css={[
                      sendButton,
                      isSuccess && successButton,
                      isError && errorButton,
                      isBooster && boosterSendButtonStyle,
                    ].filter(Boolean)}
                    loading={isSubmitting}
                    loaderLabel={sendLoadingLabel}
                    size="large"
                    kind="primary"
                    type="submit"
                    disabled={!isValid}
                  >
                    {buttonLabel}
                  </Button>
                  {isSuccess && confirmation && <p>{confirmation}</p>}
                  {isError && <p>{sendErrorMessage}</p>}
                </div>
              </Row>
            </FormikForm>
          )}
        </Formik>
      </FormContents>
      {!hideFooter && !isBooster && <HelpDeskFooter />}
    </FormContainer>
  );
};

export type ContactFormProps = {
  isBooster?: boolean;
  contactFormID?: string;
  header: string;
  confirmation: string;
  caption: string;
  teamID?: string;
  hideFooter: boolean;
  consentRequired: boolean;
  consentMessage: string | null;
  rootUrl?: string;
  isPreview?: boolean;
  licenseID?: number;
  nameLabel: string;
  emailLabel: string;
  subjectLabel: string;
  messageLabel: string;
  sendLoadingLabel: string;
  sendErrorMessage: string;
  namePlaceholder: string;
  emailPlaceholder: string;
  subjectPlaceholder: string;
  messagePlaceholder: string;
  sendLabel: string;
  sendSuccessLabel: string;
  sendErrorLabel: string;
  nameRequiredError: string;
  emailRequiredError: string;
  emailInvalidError: string;
  subjectRequiredError: string;
  messageRequiredError: string;
  consentRequiredError: string;
};

const ContactForm: React.FC<ContactFormProps> = ({
  contactFormID,
  header,
  rootUrl,
  confirmation,
  caption,
  teamID,
  licenseID,
  hideFooter,
  consentRequired,
  consentMessage,
  isPreview = false,
  isBooster = false,
  ...formProps
}) => {
  const previewNextFail = useRef(false);

  const submitForm = useCallback(
    async (fields: FormFields) => {
      if (isPreview) {
        const value = Promise.resolve({
          status: previewNextFail.current ? 401 : 200,
        });
        previewNextFail.current = !previewNextFail.current;
        return value;
      } else {
        const payload = {
          requester: {
            name: fields.name,
            email: fields.email,
          },
          subject: fields.subject,
          message: {
            text: fields.message,
          },
          licenseID: licenseID,
          ...(teamID!.trim().length > 0
            ? {
                assignment: { team: { ID: teamID }, agent: null },
                teamIDs: [teamID],
              }
            : {}),
          ...(contactFormID ? { contactFormID } : {}),
          ...(isBooster ? { isBooster: true } : {}),
        };

        return window.fetch(`${rootUrl!}/v1/externalTickets`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(payload),
        } as any);
      }
    },
    [contactFormID, isBooster, isPreview, licenseID, rootUrl, teamID]
  );

  return (
    <Form
      onSubmit={submitForm}
      header={header}
      caption={caption}
      confirmation={confirmation}
      hideFooter={hideFooter}
      consentRequired={consentRequired}
      consentMessage={consentMessage}
      isBooster={isBooster}
      {...formProps}
    />
  );
};

export default ContactForm;
