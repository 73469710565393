import React, { useEffect, useState } from "react";
import { Loader, Toast } from "@livechat/design-system";
import { css } from "@emotion/core";
import "@livechat/design-system/dist/design-system.css";
import "url-search-params-polyfill";
import ContactForm from "./ContactForm";
import FormPreview from "./FormPreview";
import ModernContactForm from "./ModernContactForm";

export type FormEmbedSettings =
  | {
      type: "legacy";
      licenseID: number;
      caption: string;
      header: string;
      confirmation: string;
      teamID: string;
      rootUrl: string;
      hideFooter: boolean;
      consentRequired: boolean;
      consentMessage: string | null;
    }
  | { type: "modern"; formID: string; licenseID: number; rootUrl: string }
  | { type: "preview"; data: string }
  | {
      type: "booster";
      groupID: string;
      livechatLicenseID: string;
      rootUrl: string;
    };

const centeredCss = css`
  width: 100vw;
  height: 100vh;
  display: flex;
  place-items: center center;

  & > * {
    margin: auto;
  }
`;

export const FullScreenLoader: React.FC = ({ children }) => (
  <div css={centeredCss}>{children}</div>
);

export const Error: React.FC = () => (
  <Toast variant="error">
    Failed to load contact form. This may be caused by misconfiguration of the
    embed. Contact site owner for assistance.
  </Toast>
);

function shouldHideFooter(
  queryParams: URLSearchParams,
  licenseID: string | null
) {
  if (!licenseID) {
    return false;
  }

  const headerName = btoa(`${licenseID}_hFI`).slice(4, 12);
  const header = queryParams.get(headerName);

  if (!header) {
    return false;
  }

  const validValue = btoa(
    `${String(licenseID).split("").reverse().join("")}_hFPP`
  );

  return validValue === header;
}

const LEGACY_LABELS = {
  nameLabel: "Name",
  emailLabel: "Email",
  subjectLabel: "Subject",
  messageLabel: "Message",
  sendLoadingLabel: "Submitting",
  sendErrorMessage:
    "Unfortunately, there was an error processing your request.\nPlease try again later.",
  namePlaceholder: "Name",
  emailPlaceholder: "Email",
  subjectPlaceholder: "Subject",
  messagePlaceholder: "Message",
  sendLabel: "Send",
  sendSuccessLabel: "Success!",
  sendErrorLabel: "Error 😿",
  nameRequiredError: "Name can't be blank",
  emailRequiredError: "Email can't be blank",
  emailInvalidError: "Invalid email address",
  subjectRequiredError: "Subject can't be blank",
  messageRequiredError: "Message can't be blank",
  consentRequiredError:
    "You need to give your consent to process your personal data",
};

function parseConfigurationQueryString(): FormEmbedSettings | null {
  const queryParams = new URLSearchParams(window.location.search);

  const isPreview = queryParams.get("isPreview") === "1";
  const previewData = queryParams.get("previewData");

  const contactFormID = queryParams.get("contactFormID");

  const licenseID = queryParams.get("licenseID");
  const parsedLicenseID = licenseID ? parseInt(licenseID, 10) : NaN;
  const caption = queryParams.get("caption") || "";
  const header = queryParams.get("header") || "";
  const confirmation = queryParams.get("confirmation") || "";
  const teamID = queryParams.get("teamID") || "";
  const consentRequired = queryParams.get("gdpr") === "checkbox";
  const consentMessage = queryParams.get("consentMessage") || null;
  const livechatLicenseID = queryParams.get("livechatLicenseID") || null;
  const groupID =
    queryParams.get("group") || queryParams.get("groupID") || null;

  const rootUrl = process.env.HELPDESK_API_ROOT_URL
    ? process.env.HELPDESK_API_ROOT_URL
    : "https://api.labs.helpdesk.com/";
  const hideFooter = shouldHideFooter(queryParams, licenseID);

  const isValidLicenseID = !Number.isNaN(parsedLicenseID);

  if (!isValidLicenseID && !isPreview && livechatLicenseID === null) {
    return null;
  }

  if (contactFormID && isValidLicenseID) {
    return {
      type: "modern",
      licenseID: parsedLicenseID,
      formID: contactFormID,
      rootUrl,
    };
  }

  if (livechatLicenseID !== null && groupID !== null) {
    return { type: "booster", groupID, livechatLicenseID, rootUrl };
  }

  if (isPreview && previewData) {
    return { type: "preview", data: previewData };
  }

  return {
    type: "legacy",
    licenseID: parsedLicenseID,
    rootUrl,
    caption,
    header,
    confirmation,
    teamID,
    hideFooter,
    consentRequired,
    consentMessage,
  };
}

export const iframeCss =
  window.top !== window
    ? css``
    : css`
        padding: 28px;
      `;

const App: React.FC = () => {
  const [configuration, setConfiguration] = useState<FormEmbedSettings | null>(
    null
  );
  const [error, toggleError] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      const configuration = parseConfigurationQueryString();
      if (configuration) {
        setConfiguration(configuration);
      } else {
        toggleError(true);
      }
    });
  }, []);

  if (!configuration && !error) {
    return (
      <FullScreenLoader>
        <Loader size="large" />
      </FullScreenLoader>
    );
  }

  if (error) {
    return (
      <FullScreenLoader>
        <Error />
      </FullScreenLoader>
    );
  }

  if (configuration) {
    switch (configuration.type) {
      case "legacy": {
        return (
          <div css={iframeCss}>
            <ContactForm {...configuration} {...LEGACY_LABELS} />
          </div>
        );
      }
      case "booster": {
        return (
          <ModernContactForm
            type="booster"
            groupID={configuration.groupID}
            livechatLicenseID={configuration.livechatLicenseID}
            rootUrl={configuration.rootUrl}
          />
        );
      }
      case "modern":
        return (
          <ModernContactForm
            type="embed"
            formID={configuration.formID}
            rootUrl={configuration.rootUrl}
            licenseID={configuration.licenseID}
          />
        );
      case "preview":
        return <FormPreview data={configuration.data} />;
      default:
        return <Error />;
    }
  }

  return null;
};

export default App;
